import React from 'react';
import GuestHeader from '../../components/GuestHeader';
import AuthHeader from '../../components/AuthHeader';

function About() {
  return (
    <>
      <AuthHeader pageTitle="About Us" />
      <div style={{ padding: '20px', fontFamily: 'Arial, sans-serif',backgroundColor:'#fff',margin:'10px' }}>
        <h1>About Us</h1>
        <p style={{ color: '#555', lineHeight: '1.6' }}>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam ut
          neque quam. Mauris tincidunt metus et venenatis faucibus. Integer
          ullamcorper mi ut dolor luctus, ut venenatis orci placerat. Quisque
          ut nisi euismod, aliquet nisi et, tincidunt ligula. In vitae
          vestibulum metus. Suspendisse potenti. Proin lacinia, quam non
          scelerisque pellentesque, ipsum purus varius ante, non dictum nunc
          turpis sit amet elit. Curabitur vel massa ac orci cursus gravida.
          Aenean vehicula, lorem ac gravida convallis, ligula nisl tempor odio,
          at fringilla eros felis a justo.
        </p>
        <p style={{ color: '#555', lineHeight: '1.6' }}>
          Sed auctor imperdiet ligula, non fermentum dolor elementum eu. Nullam
          in metus ut lorem dapibus blandit. Vivamus sed sollicitudin nulla. Etiam
          in malesuada odio. Donec at pharetra dolor. Fusce et risus nisi. Donec
          euismod, nisi ac eleifend dictum, orci sem accumsan nulla, eu viverra
          orci eros eu orci. Donec at fringilla tortor. Nam fermentum metus in
          risus tempor, non vehicula urna sollicitudin. Nam euismod lectus vel
          ipsum fermentum, sit amet posuere sem sagittis.
        </p>
        <p style={{ color: '#555', lineHeight: '1.6' }}>
          Nam eget risus non lorem volutpat scelerisque. Pellentesque viverra
          imperdiet turpis, vel feugiat purus tincidunt eu. Maecenas ultrices
          quam in odio fringilla, at luctus nisl varius. Integer ultricies orci
          sed nibh interdum, in elementum libero posuere. Fusce nec augue nec
          lacus maximus cursus. In hac habitasse platea dictumst. Nulla facilisi.
          Suspendisse eget dui convallis, lacinia nisi at, luctus nunc. Duis
          auctor ligula vel lacus ultricies, non eleifend purus auctor.
        </p>
        <p style={{ color: '#555', lineHeight: '1.6' }}>
          Cras sit amet lectus ac arcu aliquet convallis. Donec ac velit nec
          sapien pretium venenatis non vel felis. Nam ac purus mauris. Duis
          dictum, odio non vestibulum luctus, lectus velit maximus felis, id
          tincidunt lectus dui at mauris. Sed fringilla, felis non suscipit
          volutpat, libero mauris volutpat risus, id lacinia lacus justo non
          eros. Ut a purus quis orci aliquet auctor. Morbi sit amet dui nec
          risus ullamcorper tincidunt. Vivamus ultrices sem at diam aliquam,
          ac condimentum lorem dapibus.
        </p>
        <p style={{ color: '#555', lineHeight: '1.6' }}>
          Vivamus aliquam risus vel libero sollicitudin, non dignissim lacus
          pellentesque. Nulla facilisi. In pharetra, purus at dictum dictum, leo
          est venenatis est, non lacinia sapien ligula eu turpis. Etiam lacinia
          eros vitae sollicitudin dictum. Ut scelerisque interdum neque, a
          lobortis sapien scelerisque ut. Donec malesuada est nec velit cursus,
          id maximus sem consequat. Nulla tempor, mauris at tempor interdum,
          nunc dolor feugiat sapien, ac commodo libero odio in turpis.
        </p>
        {/* Add more paragraphs to simulate a long page */}
      </div>
    </>
  );
}

export default About;
