import Register from "../pages/guest/Register";


const initialState = {
    count: 0,
    isAuthenticated: false,
    user:{},
    currentGame:'Parity',
    games:[],
    wallet:0,
    records:[],
    bidType:false,
    otpSent:0,
     
};

const rootReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'LOGIN':
            return { ...state, isAuthenticated: true };
            
        case 'LOGOUT':
            return { ...state, isAuthenticated: false };
        case 'OTP_SENT':
            return {...state ,otpSent:action.payload}; 
        case 'SET_BID_TYPE':
            return {...state , bidType:action.payload};
        case 'SET_USER_DATA':
            return {...state,user:action.payload};
        case 'SET_USER_WALLET':
            return {
                    ...state,
                    wallet:action.payload 
                   };
        case 'SET_CURRENT_GAME':
            return {...state,currentGame:action.payload};
        case 'SET_GAME_DATA':
            return {...state,games:action.payload};
        case 'SET_ORDERS':
            return {...state,records:action.payload};
         
        default: 
            return state;
    }
};

export default rootReducer;