import { SpeakerWaveIcon } from '@heroicons/react/24/outline' 
import React from 'react'

function AnnounceMent() {
  return (
    <div className='announcement'>
        <ul>
            <li>
                <SpeakerWaveIcon/>
            </li>
            <li>
                Welcome to Guess the color home page , register your self and start for free , get reward tokens and enjoy as much as you can
            </li>
             
        </ul>
    </div>
  )
}

export default AnnounceMent