import React from 'react'
import { Link } from 'react-router-dom'

function FooterItem({icon,title,link,clickTo,currentIcon}) {
  return (
    <div className={`footer-item ${currentIcon}`}>
      
       
        <div className={`footer-icon ${currentIcon}`} onClick={clickTo}>
          <Link to={link}>
           {icon}
           </Link> 
        </div>
      
      {title}
      
      
      
    </div>
  )
}

export default FooterItem