// src/BankCardManager.js
import React, { useState, useEffect } from 'react';
import {
    fetchBankCards,
    addBankCard,
    updateBankCard,
    deleteBankCard,
} from './../../reducers/bank-action'
import AuthHeader from '../../components/AuthHeader';
import { PencilIcon, PlusIcon } from '@heroicons/react/24/outline';
import Button from '../../components/Button';
import { useNavigate } from 'react-router-dom';

const BankCardManager = () => {
    const [cards, setCards] = useState([]);
    const [token] = useState(localStorage.getItem('token')); // Assuming token is stored in localStorage
    const [newCard, setNewCard] = useState({ bank_name: '', account_number: '', ifsc: '', person_name: '' });
    const [editingCard, setEditingCard] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        const loadCards = async () => {
            try {
                const fetchedCards = await fetchBankCards(token);
                setCards(fetchedCards);
            } catch (error) {
                console.error('Error loading cards', error);
            }
        };

        loadCards();
    }, [token]);

    const handleAddCard = async (e) => {
        e.preventDefault();
        try {
            await addBankCard(token, newCard);
            const fetchedCards = await fetchBankCards(token);
            setCards(fetchedCards);
            setNewCard({ bank_name: '', account_number: '', ifsc: '', person_name: '' });
        } catch (error) {
            console.error('Error adding card', error);
        }
    };

    const handleUpdateCard = async (e) => {
        e.preventDefault();
        try {
            await updateBankCard(token, editingCard.id, editingCard);
            const fetchedCards = await fetchBankCards(token);
            setCards(fetchedCards);
            setEditingCard(null);
        } catch (error) {
            console.error('Error updating card', error);
        }
    };

    const handleDeleteCard = async (cardId) => {
        try {
            await deleteBankCard(token, cardId);
            const fetchedCards = await fetchBankCards(token);
            setCards(fetchedCards);
        } catch (error) {
            console.error('Error deleting card', error);
        }
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        if (editingCard) {
            setEditingCard(prev => ({ ...prev, [name]: value }));
        } else {
            setNewCard(prev => ({ ...prev, [name]: value }));
        }
    };

    return (
        <>
        <AuthHeader pageTitle="Bank Card" trailing={<PlusIcon onClick={()=>navigate('/bank-card/add')} /> }/>
        <div className="bank-card-manager" style={{background:'#fff'}}>
             

            <div className="card-list" style={{marginBlock:'20px',padding:'auto'}}> 
                 
                {cards.length==0 && <div style={{display:'flex',flexDirection:'column' ,justifyContent:'center',alignItems:'center',paddingBlock:'20px'}}>
                    <img src='https://cdn-icons-png.flaticon.com/128/7486/7486754.png'
                    style={{height:'100px'}}/>
                    <p style={{color:'gray'}}>No Bank Cards</p>
                    </div>}

                <ul>
                    {cards.map(card => (
                        <li key={card.id}>
                            {card.bank_name} - {card.account_number}
                            <div>
                                
                                
                                
                                <button className="edit-button" onClick={() => setEditingCard(card)}>Edit</button>
                                <button onClick={() => handleDeleteCard(card.id)}>Delete</button>
                            </div>
                        </li>
                    ))}
                </ul>
            </div>
        </div>
        </>
    );
};

export default BankCardManager;
