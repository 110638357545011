import { CheckBadgeIcon, ChevronLeftIcon, ChevronRightIcon, TrophyIcon } from '@heroicons/react/24/outline';
import { Ballot, Circle } from '@mui/icons-material';
import React, { useEffect, useState } from 'react';
import { LoaderIcon } from 'react-hot-toast';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';

function GameResultRecord() {
  const game = useSelector(state => state.auth.currentGame.toLowerCase());
  const games = useSelector(state => state.auth.games);
  const dispatch = useDispatch();
  
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;
  
  useEffect(() => {
    const fetchData = async () => {
      const baseUrl = 'https://backend.royale98.in/api/v1';
      const token = localStorage.getItem('token') ?? '0';
      const defaultHead = {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      };
      try { 
        const response = await axios.get(baseUrl + "/get-game-data", defaultHead);
        // Dispatch data to the store 
        dispatch({ type: 'SET_GAME_DATA', payload: response.data });
      } catch (error) {
        // Handle errors
      } finally {
      }
    };

    fetchData();
  }, [game]);

  // Calculate the index range for the current page
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = games.slice(indexOfFirstItem, indexOfLastItem);
  const totalPages = Math.ceil(games.length / itemsPerPage);

  // Pagination handlers
  const handleNext = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePrev = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  return (
    <div className='gameresult__record'>
      <div className='heading'>
        <span><TrophyIcon style={{ height: '15px', width: '15px' }} /></span>
        <span className='capitalize'>{game.toLowerCase()} </span> Record
      </div> 
      <div className='table'>
        <table>
          <thead>
            <tr>
              <th>Period</th>
              <th>Price</th>
              <th>Number</th>
              <th>Result</th>
            </tr>
          </thead>
          {games.length < 1 && <LoaderIcon />}
          {games.length > 0 && (
            <tbody>
              {currentItems.map((gr) => (
                <tr key={gr.game_id}>
                  <td>{gr.game_id}</td>
                  <td>{gr.price.toString().slice(-4)}{gr[game].toString().slice(-1)}</td>
                  <td>
                    <span className={
                      gr[game] === 1 ||
                      gr[game] === 3 ||
                      gr[game] === 7 ||
                      gr[game] === 9
                        ? 'green'
                        : 'red'
                    }>
                      {gr[game]}
                    </span>
                  </td>
                  <td style={{ textAlign: 'center' }}>
                    <span className='row row-end'>
                      {gr[game] === 0 && (
                        <span className='row row-end'>
                          <Circle style={{ height: '17px', width: '17px' }} className="red" />
                          <Circle style={{ height: '17px', width: '17px' }} className="violet" />
                        </span>
                      )}
                      {gr[game] === 5 && (
                        <span className='row row-end'>
                          <Circle className="green" style={{ height: '17px', width: '17px' }} />
                          <Circle className="violet" style={{ height: '17px', width: '17px' }} />
                        </span>
                      )}
                      {gr[game] !== 5 && gr[game] !== 0 && (
                        <Circle
                          style={{ height: '17px', width: '17px' }}
                          className={
                            gr[game] === 1 ||
                            gr[game] === 3 ||
                            gr[game] === 7 ||
                            gr[game] === 9
                              ? 'green'
                              : 'red'
                          }
                        />
                      )}
                    </span>
                  </td>
                </tr>
              ))}
            </tbody>
          )}
        </table>
        <ul className='row row-between' style={{ paddingBlock: '5px' }}>
          <li style={{ height: '20px', width: '20px', cursor: 'pointer' }} onClick={handlePrev}>
            <ChevronLeftIcon />
          </li>
          <li>
            <span>{currentPage}</span>/<span>{totalPages}</span>
          </li>
          <li style={{ height: '20px', width: '20px', cursor: 'pointer' }} onClick={handleNext}>
            <ChevronRightIcon />
          </li>
        </ul>
      </div>
    </div>
  );
}

export default GameResultRecord;
