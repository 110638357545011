import React from 'react'
import AuthHeader from '../../components/AuthHeader'

function Transactions() {
  return (
   <>
   <AuthHeader pageTitle={"Transactions"}/>
   </>
  )
}

export default Transactions