import { ArrowBack } from '@mui/icons-material'
import { Icon } from '@mui/material'
import React from 'react'
import logo from '../images/logo.jpeg'
import { ArrowDownCircleIcon } from '@heroicons/react/24/outline'


function HomeHeader({pageTitle}) {
  return (
    <header className='home' > 
    <ul>
        <li>
        <img src={logo}/>
        </li>
        <li>
         {pageTitle}
        </li> 
        <li>
          <button className='row flex align-center'> <ArrowDownCircleIcon style={{height:'20px'}}/> Download</button>
        </li>
    </ul>
    </header>
  )
}

export default HomeHeader