import React from 'react'
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel'
import game1 from '../images/game1.jpg';
import game2 from '../images/game2.jpg';
import game3 from '../images/game3.jpg';

function HomeDemoCarousel() {
  return (
    <div className='homepage__carousel'>
    <Carousel showThumbs={false} autoPlay={true}>
                <div className='carousel__item'>
                    <img src={game1}  /> 
                </div>
                <div className='carousel__item'>
                    <img src={game2} /> 
                </div>
                <div className='carousel__item'>
                    <img src={game3}  /> 
                </div>
            </Carousel>
            </div>
  )
}

export default HomeDemoCarousel