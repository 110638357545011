import React, { useEffect, useState } from 'react'
import { BrowserRouter as Router, Routes,Route, Navigate } from 'react-router-dom'

import Home from './pages/guest/Home'  
import Register from './pages/guest/Register'
import ForgetPassword from './pages/guest/ForgetPassword' 
import Footer from './components/Footer'
import Login from './pages/guest/Login'
import Guess from './pages/auth/Guess'
import Profile from './pages/auth/Profile' 
import { useDispatch, useSelector } from 'react-redux'
import { Toaster } from 'react-hot-toast'
import { CircularProgress } from '@mui/material'
import { CircularProgressbar } from 'react-circular-progressbar'
import 'react-circular-progressbar/dist/styles.css';
import axios from 'axios'
import Promotion from './pages/auth/Promotion'
import Recharge from './pages/auth/Recharge';
import Withdrawal from './pages/auth/Withdrawal'
import Transactions from './pages/auth/Transactions'
import BankCard from './pages/auth/BankCard'
import ReportForm from './pages/auth/ReportForm'
import GiftPage from './pages/auth/GiftPage'
import ResetPassword from './pages/auth/ResetPassword'
import Complaints from './pages/auth/Complaints'
import About from './pages/auth/About'
import BankCardManager from './pages/auth/BankCardManager'
import AddBankCard from './pages/auth/AddBankCard'


function App() {

  const [percentage, setPercentage] = useState(5);
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  const [loading,setLoading]=useState(true);


  const dispatch = useDispatch();

    useEffect(() => {
        const fetchData = async () => {
          const baseUrl= 'https://backend.royale98.in/api/v1';
          const token=localStorage.getItem('token')??'0';
          const defaultHead={
            headers: {
                'Authorization': `Bearer ${token}`
            }
          };
          
            try {
              const interval = setInterval(() => {
                setPercentage((prev) => {
                  if (prev < 99) {
                    return prev + Math.random() * 5; // Increase by a random value
                  } else {
                    clearInterval(interval); // Stop when it reaches 99%
                    return 99;
                  }
                });
              }, 100);
                // Make the API request using Axios 
                const response = await axios.get(baseUrl+"/me",defaultHead);
                const response2 = await axios.get(baseUrl+"/get-game-data",defaultHead);
                const response3 = await axios.get(baseUrl+"/get-selections",defaultHead);
                
                // Dispatch data to the store
                dispatch({ type: 'SET_USER_DATA', payload: response.data }); 
                dispatch({ type: 'SET_USER_WALLET', payload: response.data.wallet }); 
                dispatch({type:'SET_GAME_DATA',payload:response2.data}); 
                dispatch({type:'SET_ORDERS',payload:response3.data}); 


                dispatch({type:'LOGIN'});
            } catch (error) {
                // Handle errors
                localStorage.clear();
                console.error('Error fetching data', error);
            } finally {
                // Set loading to false after request completion
                setLoading(false);
            }
        };

        fetchData();
    }, [dispatch]);
    
  return (
    <Router>
    <div className='main'> 
   {loading===true && <div className='app-loader'>
    <CircularProgressbar
              strokeWidth={2} // Adjust stroke width
              style={{ 
                width: '150px', // Adjust width
                height: '150px', // Adjust height
                color: '#fe2379', // Customize color
              }}
              value={percentage}
              text={`${Math.round(percentage)}%`}
            />
    </div>}
   {loading===false &&
    <Routes>
      <Route path='/' element={<Home/>} />
      <Route path='/guess' element={!isAuthenticated==true?<Navigate to='/login'/>:<Guess/>} />
      <Route path="/me" element={!isAuthenticated==true?<Navigate to='/login'/>: <Profile/>} />
      <Route path='/promotion' element={!isAuthenticated==true?<Navigate to='/login'/>:<Promotion/>} />
      <Route path="/recharge" element={!isAuthenticated==true?<Navigate to='/login'/>: <Recharge/>} />
      <Route path="/withdrawal" element={!isAuthenticated==true?<Navigate to='/login'/>: <Withdrawal/>} />
      <Route path="/transactions" element={!isAuthenticated==true?<Navigate to='/login'/>: <Transactions/>} />
      <Route path="/bank-card" element={!isAuthenticated==true?<Navigate to='/login'/>: <BankCardManager/>} />
      <Route path="/bank-card/add" element={!isAuthenticated==true?<Navigate to='/login'/>: <AddBankCard/>} />
      <Route path="/report" element={!isAuthenticated==true?<Navigate to='/login'/>: <ReportForm/>} />
      <Route path="/gift" element={!isAuthenticated==true?<Navigate to='/login'/>: <GiftPage/>} />
      <Route path="/report" element={!isAuthenticated==true?<Navigate to='/login'/>: <ReportForm/>} />
      <Route path="/account-security" element={!isAuthenticated==true?<Navigate to='/login'/>: <ResetPassword/>} />
      <Route path="/complains-and-suggestions" element={!isAuthenticated==true?<Navigate to='/login'/>: <Complaints/>} />
      <Route path="/about" element={!isAuthenticated==true?<Navigate to='/about-us'/>: <About/>} />




      <Route path='/login' element={!isAuthenticated==true?<Login   />:<Navigate to='/guess'/>}/>
      <Route path='/register' element={!isAuthenticated==true?<Register   />:<Navigate to='/guess'/>}/>
      <Route path='/forget-password' element={!isAuthenticated==true?<ForgetPassword   />:<Navigate to='/guess'/>}/>
    </Routes>
   }
    <Footer/>
    </div>
    <Toaster
    position='center'
    toastOptions={{
    className: '',
    style: { 
      fontSize:'12px', 
      minWidth:'200px',

      padding: '7px',
      backgroundColor:'#0000009e',
      color: '#fff',
    },
  }}
 />
    </Router>
    
  )
}

export default App