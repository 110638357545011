import React, { useState } from 'react';
import GuestHeader from '../../components/GuestHeader';
import AuthHeader from '../../components/AuthHeader';
import Input from '../../components/Input';
import Button from '../../components/Button';
import TextArea from '../../components/TextArea'; // Assuming you have a TextArea component

function Complaints() {
  const [reportType, setReportType] = useState('');
  const [description, setDescription] = useState('');
  const [screenshot, setScreenshot] = useState(null);
  const reportOptions = [
    'Bug Report',
    'Feature Request',
    'User Feedback',
    'Other'
  ];

  const handleFileChange = (e) => {
    setScreenshot(e.target.files[0]);
  };

  const handleSubmit = () => {
    // Handle form submission
    console.log('Report Submitted:', { reportType, description, screenshot });
  };

  return (
    <>
      <AuthHeader pageTitle="Complaints & Sugestions" />
      <div style={{ background: '#fff', marginTop: '2px', paddingBlock: '20px', paddingInline: '10px' }}>
        <h4>Submit a Report</h4>
      </div>
      <div style={{ background: '#fff', marginTop: '20px', paddingBlock: '20px', paddingInline: '30px', borderBottom: '3px solid #fe2379' }}>
        <h4 style={{ textAlign: 'center' }}>Report Type</h4>
        <select onChange={(e) => setReportType(e.target.value)} value={reportType} style={{ width: '100%', padding: '10px', marginTop: '10px' }}>
          <option value="" disabled>Select report type</option>
          {reportOptions.map((option, index) => (
            <option key={index} value={option}>{option}</option>
          ))}
        </select>
      </div>
      <div style={{ marginTop: '20px', background: '#fff', padding: '20px' }}>
        <TextArea
          placeholder="Describe the issue or feedback"
          value={description}
          onChange={(e) => setDescription(e.target.value)}
          rows={5}
        />
      </div>
      <div style={{ background: '#fff', padding: '20px 30px', marginTop: '10px' }}>
        <label htmlFor="screenshot">Attach Screenshot (optional):</label>
        <input type="file" id="screenshot" accept="image/*" onChange={handleFileChange} style={{ marginTop: '10px' }} />
      </div>
      <Button btnClass="btn-primary" label="Submit Report" onClick={handleSubmit} />
    </>
  );
}

export default Complaints;
