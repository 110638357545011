import { ChevronLeftIcon } from '@heroicons/react/24/outline'
import { Menu } from '@mui/material'
import React from 'react'
import { useNavigate } from 'react-router-dom';


function AuthHeader({pageTitle,isCentered=false,trailing=''}) {
    const navigate = useNavigate()
    const handleBackClick = () => {
        navigate(-1); // Equivalent to history.goBack()
      };

  return (
    <header className='auth'>
      <ul>
        <li>
          <ChevronLeftIcon  onClick={()=>handleBackClick()} style={{cursor:'pointer'}} />
        </li>
        <li style={{textAlign:'center'}}>
          {pageTitle}
        </li> 
        <li style={{width:'20px'}}>
           {trailing}
        </li>
      </ul>
    </header>
  )
}

export default AuthHeader