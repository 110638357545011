import React from 'react';

function Input({ prepend, placeholder, type = 'text', label, onChange, value,trailing='',readable=false }) {
  if (type === 'checkbox') {
    return (
      <ul className='input-ul'>
        <li>{prepend}</li>
        <li>
          <input
            readOnly={readable}
            type={type}
            onChange={onChange}
            checked={value} // For checkbox, you need to handle the checked state
          />
        </li>
        <li className='label'>{label}</li>
        
         
      </ul>
    );
  }

  return (
    <div className='input-box'>
      <div>{prepend}</div>
      <div>
        <input
          placeholder={placeholder}
          type={type}
          onChange={onChange}
          readOnly={readable}
          value={value} // For text inputs, handle the value prop
        />
      </div>
      
      {trailing!='' && (
         <div style={{maxWidth:'100px'}}> {trailing}</div>
        )}
      
    </div>
  );
}

export default Input;
