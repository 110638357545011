import { CodeBracketIcon } from '@heroicons/react/24/outline'
import React from 'react' 
import GuestHeader from '../../components/GuestHeader'
import Input from '../../components/Input'
import { DevicePhoneMobileIcon, LockClosedIcon, PhoneArrowDownLeftIcon, PhoneIcon, UserIcon } from '@heroicons/react/24/outline'
import Button from '../../components/Button'
import { useNavigate } from 'react-router-dom'

function ForgetPassword() {
  return (
    <>
    <GuestHeader pageTitle="Forget Password"/>
    <br/>
    <Input prepend={<DevicePhoneMobileIcon /> } placeholder="Enter Mobile Number"/>
    <br/>
    <Input prepend={<LockClosedIcon /> } placeholder="Enter New Password"/>
    <br/>
    <Input prepend={<LockClosedIcon /> } placeholder="Confirm New Password"/>
    <br/>
    <Input prepend={<CodeBracketIcon /> } placeholder="Verification Code"/>
    <br/>
     
    <Button label="Reset Password" btnClass="btn-primary"/>
    <br/>
     
    </>
  )
}

export default ForgetPassword