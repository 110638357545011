// src/api.js
import axios from 'axios';
import toast from 'react-hot-toast';

const API_URL = 'https://backend.royale98.in/api/v1/bank-cards';

export const fetchBankCards = async (token) => {
    try {
        const response = await axios.get(API_URL, {
            headers: {
                'Authorization': `Bearer ${token}`,
            },
        });
        return response.data.cards;
    } catch (error) {
        console.error('Error adding bank card:', error);
        
        // Handle error message
        const errorMessage = error.response?.data?.message || 'An error occurred. Please try again.';
        toast.error(errorMessage);
        
        throw error;
    }
};

export const fetchBankCard = async (token, cardId) => {
    try {
        const response = await axios.get(`${API_URL}/${cardId}`, {
            headers: {
                'Authorization': `Bearer ${token}`,
            },
        });
        return response.data.card;
    } catch (error) {
        console.error('Error fetching bank card', error);
        throw error;
    }
};

export const addBankCard = async (token, cardData) => {
    try {
        const response = await axios.post(API_URL, cardData, {
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json',
            },
        });
        toast.success('Added Successfully');
        return response.data;
         
    } catch (error) {
        console.error('Error adding bank card', error);
        toast.success(JSON.stringify(error.errors[0]));
        throw error;
    }
};

export const updateBankCard = async (token, cardId, cardData) => {
    try {
        const response = await axios.put(`${API_URL}/${cardId}`, cardData, {
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json',
            },
        });
        return response.data;
    } catch (error) {
        console.error('Error updating bank card', error);
        throw error;
    }
};

export const deleteBankCard = async (token, cardId) => {
    try {
        const response = await axios.delete(`${API_URL}/${cardId}`, {
            headers: {
                'Authorization': `Bearer ${token}`,
            },
        });
        return response.data;
    } catch (error) {
        console.error('Error deleting bank card', error);
        throw error;
    }
};
