import { ArrowBack } from '@mui/icons-material';
import React from 'react';
import { useNavigate } from 'react-router-dom';

function GuestHeader({ pageTitle,isCentered=false }) {
  const navigate = useNavigate();

  const handleBackClick = () => {
    navigate(-1); // Equivalent to history.goBack()
  };

  return (
    <header className='guest'>
      <ul className={isCentered==true?'row row-between':''}>
        <li>
          <ArrowBack onClick={handleBackClick} style={{ cursor: 'pointer' }} />
        </li>
        <li>
          {pageTitle}
        </li>
        <li>
          
        </li>
      </ul>
    </header>
  );
}

export default GuestHeader;
