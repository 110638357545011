import React from 'react'
import ProfileCard from '../../components/ProfileCard'
import ProfileOptionCard from '../../components/ProfileOptionCard'
import OptionsTab from '../../components/OptionsTab'
import AccountOptionItem from '../../components/AccountOptionItem'
import { CloudArrowDownIcon, CreditCardIcon, CursorArrowRaysIcon, DocumentChartBarIcon, FlagIcon, GiftIcon, InformationCircleIcon, ShieldCheckIcon, UserIcon, WalletIcon } from '@heroicons/react/24/outline'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import Button from '../../components/Button'
import InstallButton from '../../components/InstallButton'
 
 

function Profile() {
  const dispatch=useDispatch();
  const navigate= useNavigate();
  const logout=()=>{
      dispatch({type:'LOGOUT'});
      localStorage.clear();
      navigate('/login');
    
  }
  return (
    
    <div className='profile_main'>
        <ProfileCard /> 
        <div className='profile__card' style={{marginTop:'20px',padding:'20px 20px'}}>
            <AccountOptionItem icon={<CursorArrowRaysIcon />  } label="Promotions" clickTo={()=>navigate('/promotion')}/>
            <AccountOptionItem icon={<WalletIcon />  } label="Wallet" isAccordian={true}
            childs={[
              { label: 'Recharge', clickTo: () => navigate('/recharge') },
              { label: 'Withdrawal', clickTo: () => navigate('/withdrawal') },
              { label: 'Transactions', clickTo: () => navigate('/transactions') }
            ]}
             />

            <AccountOptionItem icon={<CreditCardIcon />  } label="Bank Card" clickTo={()=>navigate('/bank-card')}/>
            <AccountOptionItem icon={<DocumentChartBarIcon />  } label="Report" clickTo={()=>navigate('/report')}/>
            <AccountOptionItem icon={<GiftIcon />  } label="GIFT" clickTo={()=>navigate('/gift')}/>
            <AccountOptionItem icon={<ShieldCheckIcon />  } label="Account Security" clickTo={()=>navigate('/account-security')}/>
            <AccountOptionItem icon={<CloudArrowDownIcon />  } label="App Download" clickTo={() => document.querySelector('.install-button')?.click()}/>
            <AccountOptionItem icon={<FlagIcon />  } label="Complaints & Suggestions" clickTo={()=>navigate('/complains-and-suggestions')}/> 
            <AccountOptionItem icon={<InformationCircleIcon />  } label="About" clickTo={()=>navigate('/about')}/>
            <br/>
            <InstallButton /> {/* Add InstallButton component here */}
        </div>
        <br/> 
        <Button className='btn btn-logout' clickTo={logout} label="Logout" />  
        <br/>
        <br/>         
    </div>
  )
}

export default Profile