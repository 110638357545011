import React, { useState } from 'react'
import { useDispatch } from 'react-redux';

function OptionsTab() {
  const dispatch = useDispatch();
  const options=['Parity','Sapre','Bcone','Emerd']; 
  const [currentTab,setCurrentTab]=useState(0) 
  const handleGameChange=(index,game)=>{
    setCurrentTab(index);
    dispatch({type:'SET_CURRENT_GAME',payload:game})

  }
  return (
    <div className='options__tab'>
        <ul>
            {options.map((option,index)=>{
              return <li onClick={()=>handleGameChange(index,option)} className={index==currentTab?'active':''}>{option}</li>
            })}
             
        </ul>
    </div>
  )
}

export default OptionsTab