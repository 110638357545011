import React from 'react'
import OptionsTab from '../../components/OptionsTab'
import PeriodCountdown from '../../components/PeriodCountdown'
import GuessButton from '../../components/GuessButton'
import GameResultRecord from '../../components/GameResultRecord'
import GameOrderRecord from '../../components/GameOrderRecord'
import Button from '../../components/Button';
import { ArrowPathIcon } from '@heroicons/react/24/outline'
import toast from 'react-hot-toast'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'


function Guess() {
  
  const user = useSelector(state=>state.auth.user);
  const userWallet = useSelector(state=>state.auth.wallet);
  const navigate = useNavigate();
  return (
   <> 

   <div className='profile_main'>
   <div className='profile__card'>
    <br/>
    <div className='row row-start w-90'>
      <h4 style={{marginInlineEnd:'10px'}}>Available balance : {user.wallet}</h4>
      <h4> <ArrowPathIcon style={{width:'20px',height:'20px'}}/> </h4>
    </div>
    
    <ul class='row row-start w-90 mt-5 mb-10'>
      <li style={{marginInlineEnd:'20px',width:'100px'}}>
        <Button label={"Recharge"} btnClass="btn-primary" clickTo={()=>navigate('/recharge')}   custom={true}/>
      </li>
      <li>
        <Button  label="Read Rule" btnClass="btn-primary-outline"  custom={true}/>
      </li>
      <li>
        <Button  label="Bet Record" btnClass="btn-primary-outline"  custom={true}/>
      </li>
    </ul>  
   </div>
   </div> 
   <OptionsTab/>
   <PeriodCountdown/>
   <GuessButton/>
   <GameResultRecord/>
   <br/>

   <GameOrderRecord/>

   </>
  )
}

export default Guess