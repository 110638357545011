import React, { useState } from 'react';
import GuestHeader from '../../components/GuestHeader';
import { useSelector } from 'react-redux';
import toast from 'react-hot-toast';
import AuthHeader from '../../components/AuthHeader';
import Button from '../../components/Button';

function Promotion() {
    const [level,setLevel]=useState(1);
    // Access user data from Redux store
    const user = useSelector(state => state.auth.user);

    
    const copyTo = () => {
        if (user && user.ref_code) {
            // Determine the base URL based on the current domain
            const isLocalhost = window.location.hostname === 'localhost';
            const baseUrl = isLocalhost ? 'https://royale98.in/register?code=' : 'https://royale98.in/register?code=';
            const referralLink = `${baseUrl}${user.ref_code}`;
    
            if (navigator.clipboard && navigator.clipboard.writeText) {
                navigator.clipboard.writeText(referralLink)
                    .then(() => {
                        toast.success('Referral link copied to clipboard!');
                    })
                    .catch(err => {
                        toast.error('Failed to copy referral link.');
                        console.error('Failed to copy text: ', err);
                    });
            } else {
                fallbackCopyTextToClipboard(referralLink);
            }
        } else {
            toast.error('No referral code available.');
        }
    };
    
    const copyCodeTo = () => {
        if (user && user.ref_code) {
            if (navigator.clipboard && navigator.clipboard.writeText) {
                navigator.clipboard.writeText(user.ref_code)
                    .then(() => {
                        toast.success('Referral code copied to clipboard!');
                    })
                    .catch(err => {
                        toast.error('Failed to copy referral code.');
                        console.error('Failed to copy text: ', err);
                    });
            } else {
                fallbackCopyTextToClipboard(user.ref_code);
            }
        } else {
            toast.error('No referral code available.');
        }
    };
    
    function fallbackCopyTextToClipboard(text) {
        const textArea = document.createElement('textarea');
        textArea.value = text;
        textArea.style.position = 'fixed';  // Prevents scrolling to bottom of page in MS Edge.
        document.body.appendChild(textArea);
        textArea.focus();
        textArea.select();
        try {
            const successful = document.execCommand('copy');
            const msg = successful ? 'successful' : 'unsuccessful';
            toast.success(`Fallback: Copying text command was ${msg}`);
        } catch (err) {
            toast.error('Fallback: Oops, unable to copy');
            console.error('Fallback: Unable to copy', err);
        }
        document.body.removeChild(textArea);
    }


    return (
        <>
            <AuthHeader pageTitle="Promotions" />
            <div style={{background:'#fff',marginTop:'2px',paddingBlock:'20px',paddingInline:'10px'}}>
      <p style={{paddingInline:'20px',paddingBlockEnd:'10px'}}>Bonus: 300</p>
       
      <Button label="Apply all to Balance" btnClass="btn-primary"/>
     </div> 

     <div className='' style={{background:'#fff',marginTop:'20px',paddingInline:'10px',}} >
       <ul className='row row-between'>
        <li className={level==1? `level_option active`:`level_option` } onClick={()=>setLevel(1)}>
            Level 1
        </li>
        <li className={level==2? `level_option active`:`level_option` }  onClick={()=>setLevel(2)}>
            Level 2
        </li>
       </ul>
       <ul className='row row-between' style={{padding:'20px'}}>
        <li style={{textAlign:'center'}}>
            <p style={{color:'#bebebe'}}>
                Total People 
            </p>
            <p  style={{marginTop:'10px'}}>
                20
            </p>
           
        </li>
        <li style={{textAlign:'center'}}>
        <p style={{color:'#bebebe'}}>
            Contribution
            </p>
            <p style={{marginTop:'10px'}}>
                3430
            </p>
        </li>
       </ul>
       <p style={{marginBottom:'10px',color:'#bebebe'}}>My Promotion Code</p>
       <p style={{marginBottom:'10px'}}>
        {user.ref_code}
       </p>
       <hr/>
       <p style={{marginBlock:'10px',color:'#bebebe'}}>My Promotion Link</p>
       <p style={{marginBottom:'10px'}}>
       https://royale98.in/register?code={user.ref_code}
       </p>
        <ul className='row row-around p-5'>
            <li>
                <Button label={"Copy Code"} btnClass="btn-primary-outline" clickTo={()=>copyCodeTo()} custom={true} />
                
            </li>
            <li>
                <Button label={"Copy Link"}   btnClass="btn-primary-outline" clickTo={()=>copyTo()} custom={true}/>
            </li>
        </ul>
        <br/>
     </div>
        </>
    );
}

export default Promotion;
