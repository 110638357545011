import { TrophyIcon } from '@heroicons/react/24/outline';
import { Ballot, Circle } from '@mui/icons-material';
import Accordion from './Accordian';
import React, { useEffect, useState } from 'react'
import { LoaderIcon } from 'react-hot-toast';
import { useSelector } from 'react-redux'

function GameOrderRecord() {
  const game= useSelector(state=> state.auth.currentGame);  
  const [gameResults,setGameResults]= useState([]);
  const records= useSelector(state=> state.auth.records);
  // Function to generate random data
function generateRandomData(numElements) {
    const data = [];
    
    for (let i = 0; i < numElements; i++) {
      const item = {
        pid: Math.floor(Math.random() * (999999999999 - 100000000000 + 1)) + 100000000000, // Random 12-digit PID
        result: Math.floor(Math.random() * 10), // Random result between 0 and 9
        select:Math.floor(Math.random()*10),
        price: Math.floor(Math.random() * (99999 - 10000 + 1)) + 10000 // Random price between 10,000 and 999,999
      };
      data.push(item);
    }
    
    setGameResults(data);

  }
  
  useEffect(()=>{
  generateRandomData(10);
  },[game])
  
  return (

    <div className='gameresult__record'> 
        <div className='heading'>
           <span><TrophyIcon style={{height:'20px',width:'20px'}}/></span> {game} Order Result
        </div>
        {records.map((gr)=>(<Accordion title={gr.game_id} select={gr.selection} result={"aa"}  >
        <p>This is the content for section 1.</p>
        </Accordion>))}
         
        <br/>
        <br/>
        <br/><br/><br/><br/><br/>
    </div>
  )
}

export default GameOrderRecord