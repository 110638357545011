import React from 'react'
import HomeDemoCarousel from '../../components/HomeDemoCarousel'
import AnnounceMent from '../../components/AnnounceMent'
import gameboard from '../../images/board.png'
import HomeHeader from '../../components/HomeHeader'

function Home() {
  return (
    <div className='homepage'>
      <HomeHeader pageTitle="Royal 98"/>
      <HomeDemoCarousel/>
      <AnnounceMent/>

      <img src={gameboard} style={{maxWidth:'100vw',margin:'auto'}}/>
      <div style={{height:'200px'}}>
        .
        </div>
    </div>
  )
}

export default Home