import React from 'react'
import GuestHeader from '../../components/GuestHeader'
import AuthHeader from '../../components/AuthHeader'
import Input from '../../components/Input'
import Button from '../../components/Button';

function Recharge() {
  const amounts=[100,300,500,1000,2000,5000];
  const rechargeOptions=[
    'Seppay',
    'Wonpay',
    'Matrixpay'
  ]
  return (
    <>
     <AuthHeader pageTitle="Recharge"/>
     <div style={{background:'#fff',marginTop:'2px',paddingBlock:'20px',paddingInline:'10px'}}>
      <h4>Balance : 30</h4>
     </div>
     <div className='' style={{background:'#fff',marginTop:'20px',paddingBlock:'20px',paddingInline:'10px',borderBottom:'3px solid #fe2379'}}>
        <h4 style={{textAlign:'center'}}>Bank or UPI</h4>
     </div>

     <div className='' style={{marginTop:'20px'}}>
      <Input type={"text"} placeholder="Enter Amount" prepend={<img src='https://cdn-icons-png.flaticon.com/128/9382/9382295.png' style={{height:'20px',width:'20px'}}/>} />
     </div>
     <ul className='amount-card'>
      {amounts.map((amount)=><li>
         {amount}
      </li>)}

     </ul>
     <div style={{background:'#fff',padding:'20px 30px',marginTop:'10px'}}>
      <p style={{textAlign:'justify',fontSize:'14px',color:'gray'}}>Tips: Please contact royale98@gmail.com for any recharge or wallet related failure if it happens.</p>
     </div>
     <div className='rechargeOptions'>
      <ul>
        {rechargeOptions.map((e)=>
          <li>
           <ul>
             <li>
              {e}(500-20000)
             </li>
             <li>
              <input type="checkbox"/>
             </li>
           </ul>
          </li>
        )}
        
      </ul>
     </div>
     <br/>
    <Button btnClass="btn-primary" label="Recharge" />

    </>

  )
}

export default Recharge