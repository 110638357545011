import React, { useState, useEffect } from 'react';
import {
    fetchBankCards,
    addBankCard,
    updateBankCard,
    deleteBankCard,
} from './../../reducers/bank-action'
import AuthHeader from '../../components/AuthHeader';
import { PencilIcon } from '@heroicons/react/24/outline';
import Button from '../../components/Button';

function AddBankCard() {
    const [cards, setCards] = useState([]);
    const [token] = useState(localStorage.getItem('token')); // Assuming token is stored in localStorage
    const [newCard, setNewCard] = useState({ bank_name: '', account_number: '', ifsc: '', person_name: '',mobile_number:'',email:'' });
    const [editingCard, setEditingCard] = useState(null);
    const [cashoutType,setCashoutType]=useState(0);
    

    useEffect(() => {
        const loadCards = async () => {
            try {
                const fetchedCards = await fetchBankCards(token);
                setCards(fetchedCards);
            } catch (error) {
                console.error('Error loading cards', error);
            }
        };

        loadCards();
    }, [token]);

    const handleAddCard = async (e) => {
        e.preventDefault();
        try {
            await addBankCard(token, newCard);
            const fetchedCards = await fetchBankCards(token);
            setCards(fetchedCards);
            setNewCard({ bank_name: '', account_number: '', ifsc: '', person_name: '',mobile_number:'',email:'',type:cashoutType });
        } catch (error) {
            console.error('Error adding card', error);
        }
    };

    const handleUpdateCard = async (e) => {
        e.preventDefault();
        try {
            await updateBankCard(token, editingCard.id, editingCard);
            const fetchedCards = await fetchBankCards(token);
            setCards(fetchedCards);
            setEditingCard(null);
        } catch (error) {
            console.error('Error updating card', error);
        }
    };
    const handleChange = (e) => {
        const { name, value } = e.target;
        if (editingCard) {
            setEditingCard(prev => ({ ...prev, [name]: value }));
        } else {
            setNewCard(prev => ({ ...prev, [name]: value }));
        }
    };
     
    return (
        <>
        <AuthHeader pageTitle="Add Bank"/>
        <div className="bank-card-manager">
        <div style={{background:'#fff',marginTop:'2px',paddingBlock:'20px',paddingInline:'10px'}}>
            {editingCard !=true && 
      <ul className='cashoutUl'>
        <li className={cashoutType==0?'active':''} onClick={()=>setCashoutType(0)}>Bank Cash Out</li>
        <li className={cashoutType==1?'active':''}  onClick={()=>setCashoutType(1)}>Upi Cash Out</li>
        </ul>
}
     </div> 
            <div className="card-form"> 
                <form onSubmit={editingCard ? handleUpdateCard : handleAddCard}>
                { cashoutType==0 && 
                <input
                        type="text"
                        name="ifsc"
                        value={editingCard ? editingCard.ifsc : newCard.ifsc}
                        onChange={handleChange}
                        placeholder="IFSC Code"
                        required
                    />
                }
                { cashoutType==1 && 
                <input
                        type="text"
                        name="ifsc"
                        value={editingCard ? editingCard.ifsc : newCard.ifsc}
                        onChange={handleChange}
                        placeholder="UPI Id"
                        required
                    />
                }
                    <input
                        type="text"
                        name="person_name"
                        value={editingCard ? editingCard.person_name : newCard.person_name}
                        onChange={handleChange}
                        placeholder="Actual Name"
                        required
                    />
                    <input
                        type="number"
                        name="mobile_number"
                        value={editingCard ? editingCard.mobile_number : newCard.mobile_number}
                        onChange={handleChange}
                        placeholder="Mobile Number"
                        required
                    />
                     { cashoutType==0 &&  
                    <input
                        type="email"
                        name="email"
                        value={editingCard ? editingCard.email : newCard.email}
                        onChange={handleChange}
                        placeholder="Email"
                        required
                    />
                     }
                    <input
                        type="text"
                        name="bank_name"
                        value={editingCard ? editingCard.bank_name : newCard.bank_name}
                        onChange={handleChange}
                        placeholder="Bank Name"
                        required
                    />
                    { cashoutType==0 && <>
                    <input
                        type="text"
                        name="account_number"
                        value={editingCard ? editingCard.account_number : newCard.account_number}
                        onChange={handleChange}
                        placeholder="Account Number"
                        required
                    />
                   </>}
                    <ul className='' style={{marginTop:'10px',display:'flex',flexDirection:'column'}}>
                    
                         <li style={{width:'100%'}}><Button type='submit' label={editingCard ? 'Update Card' : 'Add Card'}  btnClass="btn-primary" /></li>
                         <li style={{width:'100%',marginTop:'10px'}}>
                         {editingCard && <Button clickTo={() => setEditingCard(null)} label="Cancel"  btnClass="btn-primary-outline" />   }
                         </li>
                        
                    </ul>
                    
                    
                </form>
            </div>

             
        </div>
        </>
    );
};

export default AddBankCard