import React from 'react';

function Button({ label, custom, btnClass , clickTo,type='button' }) {
  return (
    
    custom==true ? (
      <button className={`btn ${btnClass}`} onClick={clickTo} >
        {label}
      </button>
    ) : (
      <div className="input-btn">
        <button type={type} className={`btn ${btnClass}`} onClick={clickTo}>
           {label}
        </button>
      </div>
    )
  );
}

export default Button;
