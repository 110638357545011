import React from 'react';

function TextArea({ placeholder, value, onChange, rows = 3, label, style = {} }) {
  return (
    <div className='textarea-box'>
      {label && <label className='textarea-label'>{label}</label>}
      <textarea
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        rows={rows}
        style={style}
        className='textarea-input'
      />
    </div>
  );
}

export default TextArea;
