import React, { useState } from 'react';
import AuthHeader from '../../components/AuthHeader';
import Button from '../../components/Button';
import gift from '../../images/gift.jpg'
import Input from '../../components/Input';

function GiftPage() {
  const [giftCode, setGiftCode] = useState('');
  const [message, setMessage] = useState('');

  const handleRedeem = () => {
    // Replace with actual redemption logic
    if (giftCode === 'VALIDCODE') {
      setMessage('Gift code redeemed successfully!');
    } else {
      setMessage('Invalid gift code. Please try again.');
    }
  };

  return (
    <>
      <AuthHeader pageTitle="Redeem Gift Code" />
      <div style={{ backgroundImage:`url(${gift})`, backgroundSize: 'cover', 
          backgroundPosition: 'center', minHeight:'300px'}}>
       
      </div>
      <div style={{background: '#fff'}}>
      <div style={{ marginTop: '20px', padding: '20px',margin:'20px', borderRadius: '8px',   margin: '20px 20px' }}>
         <p style={{color:'gray'}}>Hi</p>
         <p  style={{color:'gray'}}>We have a gift for you</p>
         
         </div>
         <Input placeholder={"Enter Reedeem Coupon"}/>
         <br/>
        <Button btnClass="btn-primary" label="Redeem" onClick={handleRedeem} />
        {message && (
          <div style={{ marginTop: '20px', fontSize: '16px', color: message.startsWith('Invalid') ? 'red' : 'green' }}>
            {message}
          </div>
        )}
      
      </div>
    </>
  );
}

export default GiftPage;
