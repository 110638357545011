import React, { useState } from 'react'
import FooterItem from './FooterItem'
import { HomeIcon, PuzzlePieceIcon, UserIcon } from '@heroicons/react/24/outline'

function Footer() {
  const [currentIcon,setCurrentIcon]=useState(0);
  return (
    <>
     
    <footer>
        <ul>
            <li> 
            <FooterItem title='Home' link='/' clickTo={()=>setCurrentIcon(0)} currentIcon={currentIcon==0?'active':''} icon={<HomeIcon/>}/>
            </li>
            <li>
            <FooterItem title='Games' link='/guess'  clickTo={()=>setCurrentIcon(1)} currentIcon={currentIcon==1?'active':''}  icon={<PuzzlePieceIcon />}/>
            </li>
            <li>
            <FooterItem title='Profile' link='/me'  clickTo={()=>setCurrentIcon(2)} currentIcon={currentIcon==2?'active':''}  icon={<UserIcon />}/>
            </li>
        </ul>
        </footer>
        </>
  )
}

export default Footer